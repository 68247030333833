import React from "react"
import { DERMACLINIC_ALT } from "../../../constants/Constants"

const image = require("../../../images/dermaclinic/logo.png")

const LogoImage = props => {
  return <img src={image} alt={DERMACLINIC_ALT} {...props} />
}

export default LogoImage
