import React from "react"
import { DERMACLINIC_ALT } from "../../../constants/Constants"

const image = require("../../../images/dermaclinic/features.png")

const FeaturesImage = props => {
  return <img src={image} alt={DERMACLINIC_ALT} {...props} />
}

export default FeaturesImage
