import React from "react"
import IntlText from "../../components/common/intl-text-2"
import Text from "../../components/common/text"
import Layout from "../../components/layout"
import BackgroundImage from "../../components/projects/dermaclinic-images/background"
import ConfigImage from "../../components/projects/dermaclinic-images/config"
import ConfigMobileImage from "../../components/projects/dermaclinic-images/config-mobile"
import FeaturesImage from "../../components/projects/dermaclinic-images/features"
import FeaturesMobileImage from "../../components/projects/dermaclinic-images/features-mobile"
import FlowImage from "../../components/projects/dermaclinic-images/flow"
import LogoImage from "../../components/projects/dermaclinic-images/logo"
import Screenshot1Image from "../../components/projects/dermaclinic-images/screenshot-1"
import Screenshot2Image from "../../components/projects/dermaclinic-images/screenshot-2"
import UiImage from "../../components/projects/dermaclinic-images/ui"
import UserFlowImage from "../../components/projects/dermaclinic-images/user-flow"
import ProjectContainer from "../../components/projects/project-container"
import ProjectContent from "../../components/projects/project-content"
import ProjectIntro from "../../components/projects/project-intro"

const tags = ["Product Thinking", "UX / UI"]

const technologies = "Sketch, Zeplin, Invision"

const HowWeHelp = () => {
  return (
    <div className="row how-we-help">
      <div className="col-12 col-lg-6 left-column">
        <IntlText id="dermaclinic.body2" component="h1"/>
      </div>
      <div className="col-12 col-lg-6 right-column">
        <IntlText id="dermaclinic.body3"  component="h2"/>
      </div>
    </div>
  )
}

const WhatWeDo = () => {
  return (
    <div className="row what-we-do">
      <div className="col-12 col-md-4">
        <Text id="technologies" />
        <Text>
          <strong>{technologies}</strong>
        </Text>
      </div>
      <div className="col-12 col-md-4">
        <Text id="platforms" />
        <Text>
          <IntlText id="dermaclinic.platforms" component="strong" />
        </Text>
      </div>
      <div className="col-12 col-md-4">
        <Text id="services" />
        <Text>
          <IntlText id="dermaclinic.services" component="strong" />
        </Text>
      </div>
    </div>
  )
}

const Screenshots = () => {
  return (
    <div className="screenshots-container dermaclinic">
      <div className="images">
        <Screenshot1Image className="screenshot-img" />
        <div className="project-content">
          <div className="content-box white">
            <div className="title">
              <IntlText id="dermaclinic.body16" />
            </div>
            <div className="description">
              <Text id="dermaclinic.body17" />
            </div>
          </div>
        </div>
        <Screenshot2Image className="screenshot-img" />
      </div>
    </div>
  )
}

const DermaClinicPage = () => {
  return (
    <Layout
      pageTitle="Projects"
      title="Dermaclinic´s software development challenge - Sysgarage"
      description="Check out the software development project for Dermaclinic that our company has successfully tackled. ​Let us show you how we can help!"
      hideHeader
      noMargin
      hideContact
    >
      <ProjectContainer>
        <div className="sg-container">
          <ProjectIntro
            tags={tags}
            logo={<LogoImage className="logo-image" />}
            textId="dermaclinic.body1"
            background={<BackgroundImage className="background-image" />}
          />
          <HowWeHelp />
          <WhatWeDo />
        </div>
        <ProjectContent
          titleId="dermaclinic.body4"
          descriptionId="dermaclinic.body5"
          image={<UserFlowImage />}
          imageMobile={<UserFlowImage />}
          imageFullWidth
        />
        <ProjectContent
          titleId="dermaclinic.body6"
          descriptionId="dermaclinic.body7"
          image={<ConfigImage />}
          imageMobile={<ConfigMobileImage />}
          imageFullWidth
          hasHtml
        />
        <ProjectContent
          titleId="dermaclinic.body8"
          descriptionId="dermaclinic.body9"
          image={<FeaturesImage />}
          imageMobile={<FeaturesMobileImage />}
        />
        <ProjectContent
          titleId="dermaclinic.body10"
          descriptionId="dermaclinic.body11"
          image={<FlowImage />}
          imageMobile={<FlowImage />}
          imageFullWidth
          hasHtml
        />
        <ProjectContent
          titleId="dermaclinic.body12"
          descriptionId="dermaclinic.body13"
          image={<UiImage />}
          imageMobile={<UiImage />}
          imageFullWidth
        />
        <ProjectContent
          titleId="dermaclinic.body14"
          descriptionId="dermaclinic.body15"
        >
          <Screenshots />
          <div className="blank-space" />
        </ProjectContent>
      </ProjectContainer>
    </Layout>
  )
}

export default DermaClinicPage
