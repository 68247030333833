import React from "react"
import { DERMACLINIC_ALT } from "../../../constants/Constants"

const image = require("../../../images/dermaclinic/config-mobile.png")

const ConfigMobileImage = props => {
  return <img src={image} alt={DERMACLINIC_ALT} {...props} />
}

export default ConfigMobileImage
